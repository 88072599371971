<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<launch_nav :site="site" :preview="preview" />

				<div class="w-100 h-100 position-relative overflow-hidden d-flex">

					<div class="w-100 h-100 position-absolute top-0 start-0 p-4 d-flex align-items-start overflow-auto" style="z-index: 1">

						<div class="container">

							<div class="row h-100 justify-content-center mb-4">

								<div class="col-12" style="max-width: 752px;">

									<div class="card mb-4 bg-primary text-white border-0 position-relative" style="cursor: pointer;"  data-bs-toggle="modal" data-bs-target="#seo-modal">

										<div class="card-body pe-4 p-0 d-flex align-items-center justify-content-between">

											<div class="d-flex align-items-center">
												<div class="me-2">
													<div class="mx-auto overflow-hidden" style="width: 100px; height: 100px; margin-top: -20px">
														<img src="@/assets/img/bella.png" class="img-fluid">
													</div>
												</div>

												<div>
													<p class="fw-bold mb-0">Click here if you'd like me to write your seo.</p>
												</div>
											</div>

											<div class="d-flex align-items-center ms-4">
												<span v-if="!loading" class="material-icons-outlined">east</span>
												<span v-else class="spinner-border spinner-border-sm d-block ms-3" role="status">
													<span class="visually-hidden">Loading...</span>
												</span>
											</div>

										</div>

									</div>

									<div v-for="( page ) in wp_pages" :key="'page-' + page.ID">

										<form @submit.prevent="submit_page( page )">

											<div class="card mb-3 w-100">
												<div class="card-body p-3">
													<div class="d-flex align-items-start mb-3">
														<div class="flex-grow-1 me-4">
															<p class="seo-page-name mb-0">{{ page.post_title }}</p>
															<p class="seo-page-url mb-2">{{ site.settings['url'] }}</p>

															<input type="text" name="seo_title" v-model="page['seo_title']" class="seo-title form-control mb-1" placeholder="Enter SEO title">

															<textarea name="seo_description" v-model="page['seo_description']" class="seo-description form-control mb-3" placeholder="Enter SEO description"></textarea>

															<btn_submit 
																:label="'Update ' + page.post_title" 
																icon="check"  
																:icon_after="true" 
																:loading="loading" 
															/>
														</div>
														<div style="width: 150px;">
															<form_control
																type="file" 
																name="dark_logo"
																label="Featured Image"
																placeholder="Upload"
																field_class="form-control rounded-0 border-0 bg-transparent" 
																v-model="page.seo_image_url"
															/>
															<div v-if="page.seo_image_url" class="card w-100 mb-3">
																<div class="img-bg"><img :src="page.seo_image_url" height="50" style="max-width: 100%;" /></div>
																<button type="button" class="btn btn-link text-danger" v-on:click="[ page.seo_image_url = '' ]">
																	Remove image
																</button>
															</div>
														</div>
													</div>	
												</div>
											</div>

										</form>

									</div>		

								</div>

							</div>

						</div>

					</div>				

				</div>

			</div>

		</div>

		<div class="modal fade" :id="'seo-modal'" tabindex="-1" :aria-labelledby="'seo-modal-label'" aria-hidden="true">

			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" :id="'seo-modal-label'">Generate SEO details</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<p>When we regenerate your SEO details do you want us to do it for all of your pages or only the pages that don't have SEO meta information yet?</p>
						<p class="alert alert-warning mb-0">If you select "All pages" then any SEO data you have added will be overwritten.</p>
					</div>
					<div class="modal-footer d-flex px-0">
						<div class="w-50 m-0 ps-3 pe-1">
							<button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="modal" v-on:click="[site.settings['seo-details'] = 1, submit('seo-details')]">All pages</button>
						</div>
						<div class="w-50 m-0 pe-3 ps-1">
							<button type="button" class="btn btn-outline-secondary w-100" data-bs-dismiss="modal" v-on:click="[site.settings['seo-details'] = 2, submit('seo-details')]">Empty pages</button>
						</div>
					</div>
				</div>
			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import launch_nav from '@/components/launch_nav'
import btn_submit from '@/components/btn_submit'
import form_control from '@/components/form_control'
import site_service from '@/services/site_service'
import page_service from '@/services/page_service'

export default {
	name: 'site.wordpress',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		launch_nav,
		btn_submit,
		form_control
	},

	data() {
		return {
			loading: false,
			site: null,
			version: 0,
			wp_pages: []
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},
	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.get_wp_pages()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async submit( override = '' )
		{
			this.loading = true 

			this.reset_error()

			this.site.question = override

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

				this.version++

				this.get_wp_pages()

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},

		async get_wp_pages()
		{			
			this.reset_error()

			this.loading = true

			await page_service.get_wordpress( this.$route.params.workspace_id, this.$route.params.site_id, '?flatten=true' ).then(( response ) => {

				this.wp_pages = response.data

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async submit_page( page )
		{
			this.loading = true 

			this.reset_error()

			page_service.update_wordpress_seo( this.$route.params.workspace_id, this.$route.params.site_id, page ).then(() => {

				this.success = 'Updated successfully'

				this.version++

				this.get_wp_pages()

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},
	}
}
</script>